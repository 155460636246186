// @ts-nocheck

function fireEvent(element, event) {
    if (document.createEventObject) {
        // dispatch for IE
        //console.log("in IE FireEvent");
        const evt = document.createEventObject();
        return element.fireEvent("on" + event, evt);
    } else {
        // dispatch for firefox + others
        //console.log("In HTML5 dispatchEvent");
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent(event, true, true); // event type,bubbling,cancelable
        return !element.dispatchEvent(evt);
    }
}

function changeLanguage(lang) {
    const jObj = document.querySelector(".goog-te-combo");
    // If the element exists, proceed
    if (jObj) {
        // Set the value of the select element (similar to jObj.val(lang) in jQuery)
        jObj.value = lang;
        fireEvent(jObj, "change");
    }
}

export function googleTranslateElementInit() {
    new (window as any).google.translate.TranslateElement(
        {
            multiLanguagePage: true,
            pageLanguage: "lt",
            includedLanguages: "en",
            layout: (window as any).google.translate.TranslateElement
                .FloatPosition.NONE,
            autoDisplay: false,
        },
        "translate-section"
    );
    setTimeout(() => {
        console.log("before changeLanguage");
        changeLanguage("en");
    }, 500);
}

export function initGoogleTranslateScript() {
    const existingScript = document.querySelector(
        'script[src="https://translate.google.com/translate_a/element.js"]'
    );

    if (existingScript) {
        existingScript.remove(); // Remove old script if it exists
    }

    // Create a new script element
    const script = document.createElement("script");
    script.src =
        "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);

    // // Once the script loads, call the callback to initialize the widget
    // script.onload = () => {
    //     console.log("onload");
    //     setTimeout(() => {
    //         googleTranslateElementInit();
    //     }, 100);
    // };
}

export function googleTranslateCleanup() {
    console.log("cleanup call");
    const iframe = document.getElementById(":1.container") as HTMLIFrameElement; // Replace 'your-iframe-id' with the actual iframe ID
    if (iframe && iframe.contentWindow) {
        const iframeDocument =
            iframe.contentWindow.document || iframe.contentDocument;
        const closeButton = iframeDocument.querySelector(
            '[id=":1.close"]'
        ) as HTMLButtonElement; // Replace with the correct selector

        if (closeButton) {
            closeButton.click(); // Simulate click on the close button
        }

        const iframes = document.getElementsByTagName("iframe");
        console.log("🚀 ~ googleTranslateCleanup ~ iframes:", iframes);
        for (let i = iframes.length - 1; i >= 0; i--) {
            iframes[i].parentNode.removeChild(iframes[i]);
        }

        const googElements = document.querySelectorAll(
            "[class^='goog-'], [id^='goog-']"
        );
        googElements.forEach((element) => {
            element.parentNode.removeChild(element);
        });
    }
}
