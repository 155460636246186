import React from "react";
import ContentContainer from "../../commonComps/ContentContainer/ContentContainer";

const PrivacyPolicy_EN: React.FC = () => {
    return (
        <ContentContainer>
            <div>
                <h1>Privacy Policy</h1>
                <h2>1. Introduction</h2>
                <p>
                    RepairPunk (&nbsp;we&nbsp;, &nbsp;us&nbsp;, or
                    &nbsp;our&nbsp;) is committed to safeguarding the privacy
                    and personal information of our users. This Privacy Policy
                    outlines how we collect, use, disclose, and protect your
                    personal information in compliance with the Personal
                    Information Protection and Electronic Documents Act
                    (PIPEDA), the Ontario Consumer Protection Act 2002, and
                    where applicable, the privacy laws of Alberta, British
                    Columbia, Quebec, and other relevant jurisdictions.
                </p>

                <h2>2. Notice at Collection</h2>
                <p>
                    We collect personal information that identifies, describes,
                    or is reasonably capable of being associated with you to
                    provide and improve our services. This Notice explains what
                    personal information we collect, the purposes for which we
                    collect it, and how it is used.
                </p>

                <h2>3. What Information We Collect and Why</h2>
                <p>
                    We collect and process your personal information for various
                    purposes, including but not limited to:
                    <ul>
                        <li>
                            <strong>To provide services:</strong> We collect
                            identifiers such as your name, postal address, phone
                            number, email address, and account details to manage
                            our relationship with you, process payments, and
                            fulfill your service requests.
                        </li>
                        <li>
                            <strong>For analytics purposes:</strong> We analyze
                            data such as your IP address, browsing history, and
                            interaction with our website to improve our services
                            and user experience.
                        </li>
                        <li>
                            <strong>For tailored advertising:</strong> We use
                            your browsing activity, location data, and purchase
                            history to deliver personalized ads. You can manage
                            your cookie preferences and opt-out of tailored
                            advertising through your browser settings or
                            industry opt-out mechanisms.
                        </li>
                    </ul>
                </p>

                <h2>4. Disclosure of Personal Information to Third Parties</h2>
                <h3>4.1 Confidentiality of Data</h3>
                <p>
                    We are committed to maintaining the confidentiality of your
                    personal information. Your data may only be disclosed to
                    third parties if required to fulfill a contract, for legal
                    obligations, or for other lawful purposes.
                </p>

                <h3>4.2 Data Processors</h3>
                <p>
                    We may share your personal information with data processors
                    who provide services on our behalf. These processors are
                    authorized to use your information only as necessary to
                    perform their services and must adhere to confidentiality
                    obligations and applicable privacy laws.
                </p>

                <h3>4.3 International Data Transfers</h3>
                <p>
                    If we transfer your personal information to a third-party
                    service provider located outside Canada, we ensure that
                    appropriate safeguards such as standard contractual clauses
                    or binding corporate rules are in place to protect your data
                    in accordance with applicable laws.
                </p>

                <h2>5. Security Measures</h2>
                <p>
                    We implement appropriate technical and organizational
                    security measures, including encryption, access controls,
                    and secure storage, to protect your personal information
                    from unauthorized access, use, and disclosure. However,
                    please be aware that no electronic transmission of
                    information can be entirely secure. While we strive to
                    protect your personal information, we cannot guarantee its
                    absolute security.
                </p>

                <h2>6. Fraud Prevention and Detection</h2>
                <p>
                    We may use your personal information to protect and defend
                    the rights and property of RepairPunk, our employees, and
                    business partners. This includes monitoring for and
                    preventing fraud, unauthorized transactions, claims, and
                    other liabilities.
                </p>

                <h2>7. Change of Purpose</h2>
                <p>
                    We will only use your personal information for the purposes
                    for which we collected it unless we reasonably consider that
                    we need to use it for another reason that is compatible with
                    the original purpose. If we need to use your personal
                    information for an unrelated purpose, we will notify you and
                    explain the legal basis that allows us to do so. We may
                    process your personal information without your knowledge or
                    consent where required or permitted by law.
                </p>

                <h2>8. Non-Discrimination Policy</h2>
                <p>
                    We will not discriminate against you for exercising any of
                    your privacy rights under PIPEDA or other applicable privacy
                    laws. This means we will not deny you services, charge you
                    different prices, or provide a different level or quality of
                    service because you have exercised your privacy rights.
                </p>

                <h2>9. Data Subject Rights Management</h2>
                <h3>9.1 Rights Overview</h3>
                <p>As a data subject, you have the following rights:</p>
                <ul>
                    <li>
                        <strong>Right to Know:</strong> The right to be informed
                        about the processing of your data.
                    </li>
                    <li>
                        <strong>Right to Access:</strong> The right to access
                        your personal data.
                    </li>
                    <li>
                        <strong>Right to Rectification:</strong> The right to
                        correct inaccurate data.
                    </li>
                    <li>
                        <strong>Right to Erasure:</strong> The right to request
                        the deletion of your data (with certain legal
                        exceptions).
                    </li>
                    <li>
                        <strong>Right to Restrict Processing:</strong> The right
                        to restrict the processing of your data.
                    </li>
                    <li>
                        <strong>Right to Object:</strong> The right to object to
                        the processing of your data.
                    </li>
                    <li>
                        <strong>Right to Data Portability:</strong> The right to
                        transfer your data to another service provider.
                    </li>
                    <li>
                        <strong>
                            Right to Avoid Automated Decision-Making:
                        </strong>{" "}
                        The right to not be subject to decisions based solely on
                        automated processing.
                    </li>
                </ul>

                <h3>9.2 How to Exercise Your Rights</h3>
                <p>
                    To exercise your rights, you may contact us at{" "}
                    <a href="mailto:hey@repairpunk.com">hey@repairpunk.com</a>.
                    We may require you to verify your identity before processing
                    your request. We will respond to your request within 30 days
                    in compliance with PIPEDA.
                </p>

                <h2>10. Cookie Policy</h2>
                <h3>10.1 Use of Cookies</h3>
                <p>
                    We use cookies and similar tracking technologies on our
                    website to enhance user experience, analyze usage, and
                    improve our services.
                </p>

                <h3>10.2 Control of Cookies</h3>
                <p>
                    You can control cookie preferences through your browser
                    settings. Disabling cookies may affect the functionality of
                    our website.
                </p>

                <h3>10.3 Consent for Non-Essential Cookies</h3>
                <p>
                    We require your express consent for the use of non-essential
                    cookies. You can provide this consent when you first visit
                    our website and you may withdraw it at any time through your
                    browser settings or by contacting us.
                </p>

                <h2>11. Marketing Communications</h2>
                <h3>11.1 Consent for Marketing</h3>
                <p>
                    With your consent, we may contact you by email or phone to
                    inform you about our activities and services. Your consent
                    is not a condition for purchasing our services.
                </p>

                <h3>11.2 Opt-Out</h3>
                <p>
                    You can opt out of receiving marketing communications at any
                    time by following the unsubscribe link in our communications
                    or contacting us directly.
                </p>

                <h3>11.3 Processing Opt-Out Requests</h3>
                <p>
                    We strive to process opt-out requests promptly, but you may
                    still receive communications while we update our systems.
                </p>

                <h2>12. Data Breach Notification</h2>
                <h3>12.1 Data Breach Response</h3>
                <p>
                    In the event of a data breach that poses a real risk of
                    significant harm, we will notify the Office of the Privacy
                    Commissioner of Canada and the affected individuals as
                    required by PIPEDA. Additionally, we will comply with any
                    specific breach notification requirements applicable under
                    Ontario law.
                </p>

                <h2>13. Updates to This Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time to
                    reflect changes in our practices or applicable laws. We will
                    notify you of significant changes through our website or
                    other means of communication.
                </p>

                <h2>14. Contact Information</h2>
                <p>
                    If you have any questions about this Privacy Policy or your
                    personal information, please contact us at:
                </p>
                <p>
                    <strong>Email:</strong>{" "}
                    <a href="mailto:hey@repairpunk.com">hey@repairpunk.com</a>
                    <br />
                    <strong>Address:</strong> Krokuvos g. 53-3, LT-09306 Vilnius
                </p>
            </div>
        </ContentContainer>
    );
};

export default PrivacyPolicy_EN;
