import React from "react";
import "vanilla-cookieconsent";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { observer } from "mobx-react-lite";
import useStore from "../store";
import { transStr } from "@FEShared/i18n";
import { countryClientPageToMeta } from "@Shared/util/clientPagesMeta";

const clientPageMeta = countryClientPageToMeta(window._COUNTRY);

function onAccept() {
    window.gtag?.("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
    });
    window.fbq?.("consent", "grant");
}

const useCookieConsent = observer(() => {
    const GS = useStore();

    React.useEffect(() => {
        window.addEventListener("load", () => {
            if (!window._IS_PROD) return;

            if (window.location.search.toLowerCase().includes("kurohudas")) {
                onAccept();
                return;
            }

            const cc = initCookieConsent();
            cc.run({
                cookie_same_site: "None",
                current_lang: "lt",
                autoclear_cookies: true, // default: false
                page_scripts: true, // default: false
                // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
                // delay: 0,                               // default: 0
                // auto_language: '',                      // default: null; could also be 'browser' or 'document'
                // autorun: true,                          // default: true
                // force_consent: false,                   // default: false
                // hide_from_bots: false,                  // default: false
                // remove_cookie_tables: false             // default: false
                // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
                // cookie_expiration: 182,                 // default: 182 (days)
                // cookie_necessary_only_expiration: 182   // default: disabled
                // cookie_domain: location.hostname,       // default: current domain
                // cookie_path: '/',                       // default: root
                // cookie_same_site: 'Lax',                // default: 'Lax'
                // use_rfc_cookie: false,                  // default: false
                // revision: 0,                            // default: 0
                onFirstAction: function (user_preferences, cookie) {
                    // console.log("onFirstAction", user_preferences, cookie);
                    // callback triggered only once on the first accept/reject action
                },
                onAccept: function (cookie) {
                    onAccept();
                },
                // onChange: function (cookie, changed_categories) {
                // console.log("onChange");
                // console.log("onChange", changed_categories);
                // callback triggered when user changes preferences after consent has already been given
                // },
                gui_options: {
                    consent_modal: {
                        layout: "cloud",
                        position: "bottom center",
                    },
                },
                force_consent: true,
                languages: {
                    lt: {
                        consent_modal: {
                            title: transStr("Mes naudojame slapukus", {
                                id: "8lF2J84N",
                            }),
                            description: transStr(
                                "Mes naudojame savo ir trečiųjų šalių slapukus siekdami suasmeninti turinį ir skelbimus, teikti medijos funkcijas ir analizuoti srautą. Taip pat dalijamės informacija apie tai, kaip naudojatės mūsų svetaine su savo socialinių tinklų, reklamos ir analizės partneriais.",
                                { id: "NCemEbHd" }
                            ),
                            primary_btn: {
                                text: transStr("Leisti visus", {
                                    id: "le27kbjp",
                                }),
                                role: "accept_all", // 'accept_selected' or 'accept_all'
                            },
                            secondary_btn: {
                                text: transStr("Daugiau pasirinkimų", {
                                    id: "zQg52fnn",
                                }),
                                role: "settings", // 'settings' or 'accept_necessary'
                            },
                        },
                        settings_modal: {
                            title: transStr("Slapukų nustatymai", {
                                id: "KDbjID6W",
                            }),
                            save_settings_btn: transStr(
                                "Išsaugoti nustatymus",
                                { id: "Cj8oiEpQ" }
                            ),
                            accept_all_btn: transStr("Leisti visus", {
                                id: "nUOTH3U3",
                            }),
                            close_btn_label: transStr("Uždaryti", {
                                id: "gbRODWGA",
                            }),
                            cookie_table_headers: [
                                {
                                    col1: transStr("Pavadinimas", {
                                        id: "E3exBg2H",
                                    }),
                                },
                                {
                                    col2: transStr("Teikėjas", {
                                        id: "IkZ58abN",
                                    }),
                                },
                                {
                                    col3: transStr("Galiojimo laikas", {
                                        id: "iOq6j2Qp",
                                    }),
                                },
                                {
                                    col4: transStr("Paskirtis", {
                                        id: "JFj2GERn",
                                    }),
                                },
                            ],
                            blocks: [
                                {
                                    title: "",
                                    description: transStr(
                                        'Mes ir mūsų partneriai saugo ir (arba) pasiekia įrenginyje esančią informaciją, tokią kaip slapukai, ir apdoroja asmens duomenis, tokius kaip unikalūs identifikatoriai ir standartinė įrenginio siunčiama informacija, skirta toliau nurodytiems tikslams. Ši informacija Jūsų tiesiogiai neidentifikuoja, tačiau naudojant slapukus Jūsų patirtis mūsų puslapyje gali būti labiau suasmeninta. Jūs galite nuspręsti neleisti tam tikrų tipų slapukams veikti. Paspauskite ant skirtingų kategorijų antraščių, jei norite sužinoti daugiau ir pakeisti numatytuosius nustatymus. Tačiau turėtumėte žinoti, kad tam tikrų tipų slapukų blokavimas gali turėti įtakos jūsų patyrimui šioje svetainėje ir mūsų siūlomoms paslaugoms. Daugiau informacijos galite rasti mūsų <a target="_blank" href="{url}" class="cc-link">privatumo ir slapukų politikoje</a>.',
                                        {
                                            id: "MR18tGZ8",
                                            data: {
                                                url: clientPageMeta.PRIVACY_POLICY.url,
                                            },
                                        }
                                    ),
                                },
                                {
                                    title: transStr(
                                        "Griežtai būtinai slapukai",
                                        { id: "OID5Zd0M" }
                                    ),
                                    description:
                                        "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                                    toggle: {
                                        value: "necessary",
                                        enabled: true,
                                        readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                                    },
                                    cookie_table: [
                                        {
                                            col1: "cc_cookie", // match all cookies starting with "_ga"
                                            col2: window._COUNTRY_META.domain,
                                            col3: transStr("1 metai", {
                                                id: "5VmReA2p",
                                            }),
                                            col4: transStr(
                                                "Talpina vartotojo slapukų sutikimo nustatymus",
                                                { id: "MZr4LQWF" }
                                            ),
                                            is_regex: true,
                                        },
                                    ],
                                },
                                {
                                    title: transStr("Rinkodara", {
                                        id: "xzXjfm3X",
                                    }),
                                    description: transStr(
                                        "Rinkodaros slapukai naudojami lankytojams sekti daugelyje svetainių Tuo siekiama rodyti atskiram naudotojui pritaikytus ir jį dominančius skelbimus – tokie skelbimai vertingesni leidėjams ir trečiųjų šalių reklamuotojams.",
                                        { id: "Q17jjv09" }
                                    ),
                                    toggle: {
                                        value: "analytics",
                                        enabled: true,
                                        readonly: false,
                                    },
                                    cookie_table: [
                                        {
                                            col1: "_fbp",
                                            col2: "Meta Platforms, Inc.",
                                            col3: "2 years",
                                            col4: "Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.",
                                        },
                                        {
                                            col1: "_gcl_au",
                                            col2: "Google",
                                            col3: transStr("3 mėnesiai", {
                                                id: "QHGHyqVL",
                                            }),
                                            col4: "Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.",
                                        },
                                        {
                                            col1: "IDE",
                                            col2: "Google",
                                            col3: transStr("3 mėnesiai", {
                                                id: "hDj7zVob",
                                            }),
                                            col4: "Used by Google DoubleClick to register and report the website user's actions after viewing or clicking one of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.",
                                        },
                                        {
                                            col1: "pagead/landing",
                                            col2: "Google",
                                            col3: transStr("Sesija", {
                                                id: "T85LOnV3",
                                            }),
                                            col4: "Collects data on visitor behaviour from multiple websites, in order to present more relevant advertisement - This also allows the website to limit the number of times that they are shown the same advertisement.",
                                        },
                                        {
                                            col1: "tr",
                                            col2: "Meta Platforms, Inc.",
                                            col3: transStr("Sesija", {
                                                id: "jFpLejsa",
                                            }),
                                            col4: "Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers.",
                                        },
                                        {
                                            col1: "ads/ga-audiences",
                                            col2: "Google",
                                            col3: transStr("Sesija", {
                                                id: "xb9Fcgrr",
                                            }),
                                            col4: "Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor's online behaviour across websites..",
                                        },
                                    ],
                                },
                                {
                                    title: transStr("Statistika", {
                                        id: "GE3iqW0M",
                                    }),
                                    description: transStr(
                                        "Naudodami statistikos slapukus, kurie renka anoniminę informacija ir teikia jos ataskaitas, svetainės savininkai gali sužinoti, kaip lankytojai sąveikauja su svetaine.",
                                        { id: "jeBGO6Ap" }
                                    ),
                                    toggle: {
                                        value: "targeting",
                                        enabled: true,
                                        readonly: false,
                                    },
                                    cookie_table: [
                                        {
                                            col1: "_ga", // match all cookies starting with "_ga"
                                            col2: "Google",
                                            col3: transStr("2 metai", {
                                                id: "dEKptmpZ",
                                            }),
                                            col4: "Registers a unique ID that is used to generate statistical data on how the visitor uses the website",
                                            is_regex: true,
                                        },
                                        {
                                            col1: "_ga_#",
                                            col2: "Google",
                                            col3: transStr("2 metai", {
                                                id: "9C4LRJWA",
                                            }),
                                            col4: "Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.",
                                        },
                                        {
                                            col1: "_gat",
                                            col2: "Google",
                                            col3: transStr("1 diena", {
                                                id: "ihbaQ3ZG",
                                            }),
                                            col4: "Used by Google Analytics to throttle request rate",
                                        },
                                        {
                                            col1: "_gid",
                                            col2: "Google",
                                            col3: transStr("1 diena", {
                                                id: "ylgcMEyU",
                                            }),
                                            col4: "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
                                        },
                                        {
                                            col1: "collect",
                                            col2: "Google",
                                            col3: transStr("Sesija", {
                                                id: "bCUAxPd7",
                                            }),
                                            col4: "Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.",
                                        },
                                    ],
                                },
                            ],
                        },
                    },
                },
            });
        });
    }, []);
    return null;
});

export default useCookieConsent;
