import Box from "@FEShared/components/UI/Box/Box";
import React from "react";
import Section from "../Section/Section";
import * as S from "./ReviewsSection.styled";
import Text from "@FEShared/components/UI/Text/Text";
import {
    OrderReview,
    useRecentReviewsQuery,
} from "@FEShared/graphql/generated/graphql";
import { observer, useLocalObservable } from "mobx-react-lite";
import gqlResHandler from "@FEShared/utils/gqlResHandler";
import { runInAction } from "mobx";
import { timeAgo } from "../../Workshop/Workshop.utils";
import formatVehicleDescriptionFE from "@FEShared/utils/formatVehicleDescriptionFE";
import Tooltip from "@mui/material/Tooltip";
import * as WS from "./ReviewsSection.styled";
import CHECKMARK_IMG from "../../../pages/Search/SearchSidebar/assets/checkmark.png";
import { Skeleton } from "@mui/material";
import { TranslatableView, transStringFunctionalFE } from "@FEShared/i18n";
import { transStr } from "@FEShared/i18n";
import {
    googleTranslateElementInit,
    initGoogleTranslateScript,
} from "./ReviewsSection.util";
import Country from "@Shared/types/enums/Country";

const regex = /[ĄČĘĖĮŠŲŪŽąčęėįšųūž]/;

let translateInittedOnce = false;

const RESPONSIVE_BREAKPOINTS = {
    superLargeDesktop: {
        breakpoint: { max: 10000, min: 1400 },
        items: 4,
        partialVisibilityGutter: 16,
    },
    mid: {
        breakpoint: { max: 1400, min: 1200 },
        items: 3,
        partialVisibilityGutter: 16,
    },
    min: {
        breakpoint: { max: 1200, min: 900 },
        items: 2,
        partialVisibilityGutter: 16,
    },
    mobile: {
        breakpoint: { max: 900, min: 0 },
        items: 1,
        partialVisibilityGutter: 24,
    },
};

const ReviewsSection: React.FC = observer(() => {
    const [recentReviews] = useRecentReviewsQuery();

    const MAX_SERVICES_TO_SHOW = 4;

    const LS = useLocalObservable(() => ({
        reviews: undefined as OrderReview[] | undefined,
    }));

    React.useEffect(() => {
        gqlResHandler(recentReviews, (data) => {
            runInAction(() => {
                LS.reviews =
                    window._COUNTRY === Country.CA
                        ? data.recentReviews.filter(
                              (r) =>
                                  !regex.test(r.workshop.name) &&
                                  r.authorName &&
                                  !regex.test(r.authorName)
                          )
                        : data.recentReviews;

                if (window._COUNTRY === Country.CA) {
                    googleTranslateElementInit();
                }
            });
        });
    }, [LS, recentReviews]);

    React.useEffect(() => {
        if (window._COUNTRY === Country.CA) {
            if (translateInittedOnce) return;
            translateInittedOnce = true;
            initGoogleTranslateScript();

            // return () => {
            //     googleTranslateCleanup();
            // };
        }
    }, []);

    return (
        <Section
            boxContainerProps={{
                sx: {
                    [`font`]: {
                        boxShadow: "none !important",
                        background: "transparent !important;",
                    },
                },
            }}
            title={transStr("Naujausi klientų atsiliepimai", {
                id: "W523htaP",
            })}
            bgcolor="#E5E7F0"
            titleProps={{
                // adjust for the removed padding right in the container
                sx: {
                    pr: "32px!important",
                },
            }}
            contentContainerProps={{
                fullWidth: true,
                noMobilePadding: true,
                sx: {
                    pr: "0!important",
                },
            }}
        >
            <S.CarouselWrapper id="translate-section">
                <S.Carousel
                    autoPlay
                    partialVisible
                    infinite
                    responsive={RESPONSIVE_BREAKPOINTS}
                    arrows={false}
                >
                    {LS.reviews
                        ? LS.reviews.map((r) => {
                              const vehicleDescr =
                                  r.vehicle &&
                                  formatVehicleDescriptionFE({
                                      vehicleBrand: r.vehicle.brand,
                                      vehicleModel: r.vehicle.model,
                                      vehicleYear: r.vehicle.year,
                                  });

                              return (
                                  <Box
                                      key={r.authorName}
                                      mr={2}
                                      rounded
                                      bgcolor="#ffffff"
                                      padding={"24px"}
                                      pointer
                                  >
                                      <Box displayFlex mb={2}>
                                          <Box
                                              displayFlex
                                              width={1}
                                              sx={(theme) => ({
                                                  [theme.breakpoints.down(
                                                      "md"
                                                  )]: {
                                                      flexDirection: "column",
                                                  },
                                              })}
                                          >
                                              <Box mr={"auto"}>
                                                  <Text>
                                                      <Text span semiBold>
                                                          {`${r.workshop.name}`}
                                                      </Text>
                                                  </Text>

                                                  <Text color="#7B7F93">
                                                      {window._COUNTRY ===
                                                          Country.LT && (
                                                          <Text
                                                              span
                                                              color="#7B7F93"
                                                          >
                                                              {`${r.workshop.city} • `}
                                                          </Text>
                                                      )}
                                                      {timeAgo(r.createdAt)}
                                                  </Text>
                                              </Box>
                                              <Box
                                                  sx={(theme) => ({
                                                      [theme.breakpoints.down(
                                                          "md"
                                                      )]: {
                                                          order: -1,
                                                      },
                                                  })}
                                              >
                                                  <Text
                                                      className="icon-star-full"
                                                      color="#E5514A"
                                                      component="i"
                                                      fontSize={24}
                                                      mr={0.5}
                                                  />
                                                  <Text
                                                      className="icon-star-full"
                                                      color="#E5514A"
                                                      component="i"
                                                      fontSize={24}
                                                      mr={0.5}
                                                  />
                                                  <Text
                                                      className="icon-star-full"
                                                      color="#E5514A"
                                                      component="i"
                                                      fontSize={24}
                                                      mr={0.5}
                                                  />
                                                  <Text
                                                      className="icon-star-full"
                                                      color="#E5514A"
                                                      component="i"
                                                      fontSize={24}
                                                      mr={0.5}
                                                  />
                                                  <Text
                                                      className="icon-star-full"
                                                      color="#E5514A"
                                                      component="i"
                                                      fontSize={24}
                                                      mr={0.5}
                                                  />
                                              </Box>
                                          </Box>
                                      </Box>
                                      <Text>{`"${r.text}"`}</Text>
                                      <Text lineHeight="1" mb={1} mt={2}>
                                          <S.CheckmarkImg src={CHECKMARK_IMG} />
                                          <Text span>
                                              <Text span semiBold>
                                                  {r.authorName?.trim()}
                                              </Text>
                                              {vehicleDescr &&
                                                  ` • ${vehicleDescr}`}
                                          </Text>
                                      </Text>
                                      {r.serviceNames &&
                                          r.serviceNames.length > 0 && (
                                              <Box>
                                                  {r.serviceNames
                                                      .slice(
                                                          0,
                                                          MAX_SERVICES_TO_SHOW
                                                      )
                                                      .map((service) => (
                                                          <WS.ReviewServiceTag
                                                              key={service.id}
                                                          >
                                                              <TranslatableView
                                                                  translatable={
                                                                      service
                                                                  }
                                                              />
                                                          </WS.ReviewServiceTag>
                                                      ))}
                                                  {r.serviceNames.length >
                                                      MAX_SERVICES_TO_SHOW && (
                                                      <Tooltip
                                                          enterTouchDelay={0}
                                                          title={transStringFunctionalFE(
                                                              r.serviceNames
                                                          )
                                                              .slice(
                                                                  MAX_SERVICES_TO_SHOW
                                                              )
                                                              .join(", ")}
                                                      >
                                                          <WS.ReviewServiceTag>
                                                              +
                                                              {r.serviceNames
                                                                  .length -
                                                                  MAX_SERVICES_TO_SHOW}
                                                          </WS.ReviewServiceTag>
                                                      </Tooltip>
                                                  )}
                                              </Box>
                                          )}
                                  </Box>
                              );
                          })
                        : new Array(10).fill(0).map((_num, i) => (
                              <Box
                                  key={i}
                                  mr={2}
                                  rounded
                                  bgcolor="#ffffff"
                                  padding={"24px"}
                                  pointer
                              >
                                  <Box displayFlex mb={2}>
                                      <Box
                                          displayFlex
                                          width={1}
                                          sx={(theme) => ({
                                              [theme.breakpoints.down("md")]: {
                                                  flexDirection: "column",
                                              },
                                          })}
                                      >
                                          <Box mr={"auto"}>
                                              <Skeleton
                                                  sx={{ mb: 1 }}
                                                  width={120}
                                                  variant="rounded"
                                              />
                                              <Skeleton
                                                  width={80}
                                                  variant="rounded"
                                              />
                                          </Box>
                                          <Box
                                              sx={(theme) => ({
                                                  [theme.breakpoints.down(
                                                      "md"
                                                  )]: {
                                                      order: -1,
                                                      mb: 1,
                                                  },
                                              })}
                                          >
                                              <Skeleton
                                                  height={25}
                                                  width={150}
                                                  variant="rounded"
                                              />
                                          </Box>
                                      </Box>
                                  </Box>
                                  <Skeleton
                                      width={"100%"}
                                      variant="rounded"
                                      sx={{ mb: 1 }}
                                  />
                                  <Skeleton
                                      width={"100%"}
                                      variant="rounded"
                                      sx={{ mb: 2 }}
                                  />
                                  <Skeleton
                                      width={"65%"}
                                      variant="rounded"
                                      sx={{ mb: 2 }}
                                  />
                                  <Box>
                                      <Skeleton
                                          width={120}
                                          variant="rounded"
                                          sx={{ mb: 1 }}
                                      />
                                      <Skeleton width={120} variant="rounded" />
                                  </Box>
                              </Box>
                          ))}
                </S.Carousel>
            </S.CarouselWrapper>
        </Section>
    );
});

export default ReviewsSection;
