import Box from "@FEShared/components/UI/Box/Box";
import Text from "@FEShared/components/UI/Text/Text";
import React from "react";
import * as S from "./NewBenefitsSection.styled";

// import CHECKMARK_IMG from "./assets/checkmark.png";
// import CURSOR_IMG from "./assets/cursor.png";
import PINPOINT_IMG from "./assets/pinpoint.png";
// import SOFA_IMG from "./assets/sofa.png";
// import SPECIALIZED_IMG from "./assets/specialized.png";
import PRICE_IMG from "./assets/price.png";
import STAR_IMG from "./assets/star.png";

import Section from "../Section/Section";
import { getSeoPageData } from "../FAQ/FAQ.util";
import { transStr, transStringFunctionalFE } from "@FEShared/i18n";
import { translatable } from "@Shared/i18n/i18n";

export const BENEFITS = [
    // {
    //     title: "Dėl remonto jauskis užtikrintai",
    //     descr: "Visom atliktom paslaugoms taikoma garantija",
    //     imgUrl: CHECKMARK_IMG,
    // },
    // {
    //     title: "Pamiršk skambučius",
    //     descr: "Registruokis internetu, kelių mygtukų paspaudimu",
    //     imgUrl: CURSOR_IMG,
    // },
    // {
    //     title: "Specializuoti autoservisai",
    //     descr: "Autoservisai specializuoti paslaugoj išskirti ženkliuku",
    //     imgUrl: SPECIALIZED_IMG,
    // },
    {
        title: translatable("Palygink kainas", { id: "GbCpPAf6" }),
        descr: translatable("Autoservisų įkainiai nurodyti visoms paslaugoms", {
            id: "Zz3WAAaB",
        }),
        imgUrl: PRICE_IMG,
    },
    {
        title: translatable("Rask artimiausią", { id: "fVYcVp1a" }),
        descr: translatable(
            "Remontą atlik kur patogiau tau - šalia namų ar darbo",
            { id: "KOnuUgij" }
        ),
        imgUrl: PINPOINT_IMG,
    },

    {
        title: translatable("Skaityk atsiliepimus", { id: "wER7ZhFy" }),
        descr: translatable("Verifikuoti klientų atsiliepimai", {
            id: "UzNuE8aw",
        }),
        imgUrl: STAR_IMG,
    },
    // {
    //     title: "Registruokis patogiu metu",
    //     descr: "Registruokis patogiu metu - kad ir po darbo valandų",
    //     imgUrl: SOFA_IMG,
    // },
];

const NewBenefitsSection: React.FC = () => {
    const seoData = getSeoPageData();

    return (
        <Section
            className="notranslate"
            title={
                seoData?.seoServiceName && seoData.type !== "DISTRICT"
                    ? transStr("{seoServiceName} - šalia tavęs", {
                          data: { seoServiceName: seoData?.seoServiceName },
                          id: "lPmMT82L",
                      })
                    : transStr("Rask geriausią servisą šalia tavęs", {
                          id: "pfgwwfCJ",
                      })
            }
            titleProps={{ variant: "h2" }}
        >
            <S.NewBenefitsSection>
                <S.FeaturesWrapper>
                    {BENEFITS.map((f) => (
                        <S.FeatureWrapper
                            key={transStringFunctionalFE(f.title)}
                        >
                            <S.FeatureImg src={f.imgUrl} />
                            <Box vertical>
                                <S.FeatureTitle>
                                    {transStringFunctionalFE(f.title)}
                                </S.FeatureTitle>
                                <Text variant="subtitle1">
                                    {transStringFunctionalFE(f.descr)}
                                </Text>
                            </Box>
                        </S.FeatureWrapper>
                    ))}
                    <S.FeatureTitle></S.FeatureTitle>
                </S.FeaturesWrapper>
            </S.NewBenefitsSection>
        </Section>
    );
};

export default NewBenefitsSection;
