import { ContentContainer } from "@FEClient/views/commonComps/ContentContainer/ContentContainer.styled";
import Box from "@FEShared/components/UI/Box/Box";
import Button from "@FEShared/components/UI/Button/Button";
import Text from "@FEShared/components/UI/Text/Text";
import React from "react";
import * as S from "./NewTimeFlow.styled";
import CALENDAR_IMG from "../assets/calendar.png";
import useStore from "@FEClient/logic/store";
import Countdown from "react-countdown";
import { computed, runInAction } from "mobx";
import DatePicker from "@FEShared/components/UI/DatePicker/DatePicker";
import { observer, useLocalObservable } from "mobx-react-lite";
import countdownRenderer from "@FEShared/utils/countdownRenderer";
import { DateTime } from "luxon";
import serviceCategoriesToWantedServices from "@Shared/util/serviceCategoriesToWantedServices";
import { TransMsg, transStr } from "@FEShared/i18n";

const nowPlusTwoH = DateTime.now().plus({ hours: 2 }).toJSDate();

const NewTimeFlow: React.FC<{
    onConfirm: () => void;
    onDecline: () => void;
}> = observer((p) => {
    const GS = useStore();
    const LS = useLocalObservable(() => ({
        forceDateErr: false,
    }));
    const SPS = GS.searchPageState;

    const order = SPS.altFlow.order;
    const primaryInv = SPS.altFlow.primaryInv;

    if (!primaryInv || !order) return null;

    const wantedServices = computed(() =>
        GS.getWorkshopSelectedServices(primaryInv.service).map((s) =>
            serviceCategoriesToWantedServices(s)
        )
    ).get();

    return (
        <ContentContainer
            vertical
            sx={(theme) => ({
                maxWidth: "600px!important",
                minHeight: 600,
                [theme.breakpoints.down("md")]: {
                    width: "100%!important",
                },
            })}
            pt={2}
        >
            <Box
                bgcolor="#ffffff"
                padding={2}
                textAlign="center"
                rounded
                mb={2}
            >
                <S.Img src={CALENDAR_IMG} />
                <Text mt={2} textAlign="center">
                    <TransMsg
                        default={
                            "{Text <{serviceName}>} negalės tavęs priimti pasirinktu laiku, tačiau pasiūlė kitus laikus:"
                        }
                        data={{
                            serviceName: primaryInv.service.name,
                            Text: (body) => (
                                <Text span semiBold>
                                    {body}
                                </Text>
                            ),
                        }}
                        id="oKTlzB1Q"
                    />
                </Text>
                <Box mt={"8px"} rounded pt={2} pb={2} textAlign="center">
                    <DatePicker
                        wantedServices={wantedServices}
                        immediateValue={Boolean(order.clientWillWait)}
                        employees={primaryInv.service.employees}
                        earliestAvailableDate={nowPlusTwoH}
                        fixedOpen
                        blockedTimeslots={primaryInv.service.blockedTimeslots}
                        disablePast
                        disablePortal
                        dateObj={GS.altFlowState.newArrivalDateObj}
                        onDateChange={(dateObj) => {
                            runInAction(() => {
                                GS.altFlowState.newArrivalDateObj = {
                                    ...GS.altFlowState.newArrivalDateObj,
                                    ...dateObj,
                                };
                            });
                        }}
                        placeholder={transStr("Pasirinkite naują laiką", {
                            id: "8QZiMpkk",
                        })}
                        calendar={primaryInv.service.workHoursCalendar}
                        lunchCalendar={primaryInv.service.lunchHoursCalendar}
                        city={primaryInv.service.city}
                        errorsEnabled
                        forceInputError={LS.forceDateErr}
                    />
                </Box>
            </Box>
            <Box
                mt="auto"
                textAlign="center"
                bgcolor="#ffffff"
                padding={2}
                rounded
            >
                <Box mb={2} sx={{ fontWeight: 500 }}>
                    <Text mb={0.5} semiBold>
                        <TransMsg
                            default={"Pasirinkimui liko laiko:"}
                            id="e0Hkv7xn"
                        />{" "}
                    </Text>
                    <Countdown
                        date={order.customerPickTimeoutAt}
                        renderer={countdownRenderer}
                    />
                </Box>
                <Button
                    sx={{ mb: 1 }}
                    fullWidth
                    onClick={() => {
                        runInAction(() => {
                            LS.forceDateErr = true;
                        });
                        if (
                            !GS.altFlowState.newArrivalDateObj.YYYY_MM_DD ||
                            !GS.altFlowState.newArrivalDateObj.HH_MM
                        )
                            return;
                        p.onConfirm();
                    }}
                >
                    <TransMsg
                        default={"Patvirtinti rezervaciją nauju laiku"}
                        id="5rDwbRrD"
                    />
                </Button>
                <Button
                    fullWidth
                    color="greyish"
                    onClick={p.onDecline}
                    sx={{ flexDirection: "column" }}
                >
                    <TransMsg
                        default={
                            "Alternatyvūs autoservisai{Text <kurie patvirtino, galintys priimti norimu/artimu laiku>}"
                        }
                        data={{
                            Text: (body) => (
                                <Text variant="subtitle2">{body}</Text>
                            ),
                        }}
                        id="4zkFaZxD"
                    />
                </Button>
            </Box>
        </ContentContainer>
    );
});

export default NewTimeFlow;
