import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import GoogleMap from "./GoogleMap/GoogleMap";
import * as S from "./SearchMap.styled";
import useIsMobile from "../../../../../../FEShared/hooks/useIsMobile";
import MobileServiceSelection from "./MobileServiceSelection/MobileServiceSelection";
import useStore from "@FEClient/logic/store";
import { observer } from "mobx-react-lite";
import { WorkshopOmitted } from "../Search.types";
import { ALL_CITIES, CityWithAll } from "@Shared/consts/CITIES";
import Box from "@FEShared/components/UI/Box/Box";
import showToast from "@FEShared/utils/showToast";
import { runInAction } from "mobx";
import Country from "@Shared/types/enums/Country";
import { transStr } from "@FEShared/i18n";
import ABTestFunctional from "@FEShared/utils/ABTestFunctional";

const CITY_TO_COORDS_MAP: Record<
    CityWithAll,
    {
        lat: number;
        lng: number;
        zoom: {
            mob: number;
            desktop: number;
        };
    }
> = ABTestFunctional({
    new: {
        Vilnius: {
            lat: 54.704909,
            lng: 25.265015,
            zoom: {
                mob: 11,
                desktop: 11,
            },
        },
        Kaunas: {
            lat: 54.897174,
            lng: 23.921823,
            zoom: {
                mob: 11,
                desktop: 11,
            },
        },
        Klaipėda: {
            lat: 55.69267177399215,
            lng: 21.15565199738188,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Panevėžys: {
            lat: 55.731401963750905,
            lng: 24.360492642963635,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Šiauliai: {
            lat: 55.93274417912639,
            lng: 23.31399448130401,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Alytus: {
            lat: 54.41619534624808,
            lng: 24.047829885647555,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        [ALL_CITIES[Country.LT]]: {
            lat: 55.27987337538571,
            lng: 24.07375031434483,
            zoom: {
                mob: 8,
                desktop: 8,
            },
        },
        /* CA Cities - tbd still adjust those cords */
        // Montreal: {
        //     lat: 45.53211073728317,
        //     lng: -73.60925418850088,
        //     zoom: {
        //         mob: 13,
        //         desktop: 12,
        //     },
        // },
        Toronto: {
            lat: 43.738277252483684,
            lng: -79.3831670867004,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Ottawa: {
            lat: 45.386833050969884,
            lng: -75.68930416199335,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Mississauga: {
            lat: 43.601080373965964,
            lng: -79.64747796978808,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Vaughan: {
            lat: 43.831111674569705,
            lng: -79.54706032077526,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Markham: {
            lat: 43.859795314893255,
            lng: -79.30125718462722,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Brampton: {
            lat: 43.70393561698673,
            lng: -79.74066322418598,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        "Richmond Hill": {
            lat: 43.8634746428507,
            lng: -79.41765896380967,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        // [ALL_CITIES[Country.CA]]: {
        //     lat: 45.5016889,
        //     lng: -73.567256,
        // },
    },
    default: {
        Vilnius: {
            lat: 54.704909,
            lng: 25.265015,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Kaunas: {
            lat: 54.897174,
            lng: 23.921823,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Klaipėda: {
            lat: 55.69267177399215,
            lng: 21.15565199738188,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Panevėžys: {
            lat: 55.731401963750905,
            lng: 24.360492642963635,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Šiauliai: {
            lat: 55.93274417912639,
            lng: 23.31399448130401,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Alytus: {
            lat: 54.39619534624808,
            lng: 24.047829885647555,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        [ALL_CITIES[Country.LT]]: {
            lat: 55.27987337538571,
            lng: 24.07375031434483,
            zoom: {
                mob: 8,
                desktop: 8,
            },
        },
        /* CA Cities - tbd still adjust those cords */
        // Montreal: {
        //     lat: 45.53211073728317,
        //     lng: -73.60925418850088,
        //     zoom: {
        //         mob: 12,
        //         desktop: 12,
        //     },
        // },
        Ottawa: {
            lat: 45.386833050969884,
            lng: -75.68930416199335,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Toronto: {
            lat: 43.738277252483684,
            lng: -79.3831670867004,
            zoom: {
                mob: 12,
                desktop: 12,
            },
        },
        Mississauga: {
            lat: 43.601080373965964,
            lng: -79.64747796978808,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Vaughan: {
            lat: 43.831111674569705,
            lng: -79.54706032077526,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Markham: {
            lat: 43.859795314893255,
            lng: -79.30125718462722,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        Brampton: {
            lat: 43.70393561698673,
            lng: -79.74066322418598,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        "Richmond Hill": {
            lat: 43.8634746428507,
            lng: -79.41765896380967,
            zoom: {
                mob: 13,
                desktop: 12,
            },
        },
        // [ALL_CITIES[Country.CA]]: {
        //     lat: 45.5016889,
        //     lng: -73.567256,
        // },
    },
    experimentKey: "SEARCH_MAP_ZOOM_LEVEL",
});

interface P {
    mapItems: WorkshopOmitted[];
    city: string;
}
const SearchMap: React.FC<P> = observer((p) => {
    const isMobile = useIsMobile();
    const GS = useStore();
    const SPS = GS.searchPageState;
    const activeMapItem = p.mapItems.find(
        (i) => i.ID === SPS.selectedServiceId
    );
    // const LS = useLocalObservable(() => ({
    //     showBanner: new Date().getDate() % 3 === 0,
    // }));

    React.useEffect(() => {
        // delete backup pos on search. Overall, this is a bit hacky. Best would be to control everything directly
        // via state, without any backups, but there wasn't a proper react gmap wrapper.
        SPS.resetPosBackup();
    }, [SPS]);

    return (
        <S.SearchMapContainer
            onTouchEnd={() => {
                const currentScrollPos =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (isMobile && currentScrollPos !== 0) {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            }}
        >
            {SPS.isLoading && (
                <S.LoaderWrapper>
                    <S.CircularLoader />
                    <S.CircularLoader />
                    <S.CircularLoader />
                </S.LoaderWrapper>
            )}
            {/* {LS.showBanner && (
                <S.LagBanner>
                    Šiuo metu gauname daug užsakymų, todėl SMS patvirtinimai
                    gali užtrukti iki 2 minučių.
                    <S.CloseIcon
                        className="icon-cross"
                        onClick={() =>
                            runInAction(() => (LS.showBanner = false))
                        }
                    />
                </S.LagBanner>
            )} */}
            <Wrapper
                apiKey={
                    window._IS_PROD
                        ? "AIzaSyDUaz1Wnv_95NR6Dcm9s24jV_VvaSX0zv0"
                        : "AIzaSyAQXWud2x-ccHSAILkHn8Rrz_AZoLQ79_g"
                }
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: isMobile ? "16px" : "98px",
                        right: "10px",
                        zIndex: 2,
                    }}
                    displayFlex
                    alignVertical="center"
                    align="center"
                    vertical
                >
                    <S.MapControlIcon
                        sx={{
                            cursor: "pointer",
                            width: "40px",
                            height: "40px",
                            bgcolor: "rgb(247, 247, 247)",
                            fontSize: 20,
                            color: "black",
                        }}
                        onClick={() => {
                            if (navigator.geolocation) {
                                navigator.geolocation.getCurrentPosition(
                                    (position: GeolocationPosition) => {
                                        runInAction(() => {
                                            SPS.currentDevicePos = {
                                                latitude:
                                                    position.coords.latitude,
                                                longitude:
                                                    position.coords.longitude,
                                            };
                                        });
                                    },
                                    () => {
                                        showToast.warn(
                                            transStr(
                                                "Įvyko klaida nustatant jūsų buvimo vietą.",
                                                { id: "BQcp6GqU" }
                                            )
                                        );
                                    }
                                );
                            } else {
                                showToast.warn(
                                    transStr(
                                        "Jūsų naršyklė/įrenginys negali nustatyti jūsų buvimo vietos.",
                                        { id: "oYkUn7lo" }
                                    )
                                );
                            }
                        }}
                        className="icon-location-arrow"
                    />
                </Box>
                <GoogleMap
                    key={p.city}
                    disabled={!!SPS.isLoading}
                    center={
                        SPS.posBackup.city === p.city && SPS.posBackup.position
                            ? SPS.posBackup.position
                            : CITY_TO_COORDS_MAP[p.city]
                    }
                    zoom={
                        SPS.posBackup.zoom ||
                        CITY_TO_COORDS_MAP[p.city].zoom[
                            isMobile ? "mob" : "desktop"
                        ]
                    }
                    mapItems={p.mapItems}
                />
            </Wrapper>
            {!GS.searchPageState.isLoading && isMobile && activeMapItem && (
                <MobileServiceSelection mapItem={activeMapItem} />
            )}
        </S.SearchMapContainer>
    );
});

export default SearchMap;
