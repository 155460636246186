import Country from "@Shared/types/enums/Country";
import { City } from "@Shared/consts/CITIES";
import LangCode from "@Shared/types/enums/LangCode";
import Domain from "@Shared/types/enums/Domain";

export type CountryMeta = {
    currencySymbol: string;
    currencyCode: string;
    currencyIconClass: string;
    langCode: LangCode;
    clientEmail: string;
    domain: Domain;
    baseUrl: string;
    defaultCity: City;
    defaultTimezone: string;
    country: string;
    countryWhere: string;
    phoneNumber: string;
    brandName: string;
    socials: {
        facebook: string;
        instagram: string;
        linkedin: string;
        x?: string;
        threads?: string;
    };
    warranty: {
        mileage: string;
        durationMonths: number;
    };
    locale: string;
};

const COUNTRY_TO_META: Record<Country, CountryMeta> = {
    LT: {
        currencySymbol: "€",
        currencyCode: "EUR",
        currencyIconClass: "icon-eur",
        langCode: LangCode.LT,
        country: "Lietuva",
        countryWhere: "Lietuvoje",
        clientEmail: "labas@nevaziuoja.lt",
        domain: Domain.NEVAZIUOJA_LT,
        baseUrl: "https://nevaziuoja.lt",
        defaultCity: "Vilnius",
        defaultTimezone: "Europe/Vilnius",
        brandName: "nevažiuoja",
        socials: {
            facebook: "https://facebook.com/nevaziuoja.lt",
            instagram: "https://instagram.com/nevaziuoja.lt",
            linkedin: "https://linkedin.com/company/nevaziuoja",
        },
        phoneNumber: "+370 677 20241",
        warranty: {
            mileage: "10,000 km",
            durationMonths: 6,
        },
        locale: "lt-LT",
    },
    CA: {
        country: "Canada",
        countryWhere: "Canada",
        currencySymbol: "$",
        currencyCode: "CAD",
        currencyIconClass: "icon-usd",
        langCode: LangCode.EN,
        clientEmail: "hey@repairpunk.com",
        domain: Domain.REPAIR_PUNK_COM,
        baseUrl: "https://repairpunk.com",
        defaultCity: "Toronto",
        defaultTimezone: "America/Toronto",
        brandName: "RepairPunk",
        socials: {
            facebook: "https://facebook.com/repairpunk",
            instagram: "https://instagram.com/repairpunk",
            linkedin: "https://linkedin.com/company/repairpunk",
            x: "https://x.com/repairpunk",
            threads: "https://threads.net/@repairpunk",
        },
        phoneNumber: "+1 289-204-5568",
        warranty: {
            mileage: "5,000 km",
            durationMonths: 3,
        },
        locale: "en-CA",
    },
};

export default function countryToMeta(
    country: string
): (typeof COUNTRY_TO_META)[keyof typeof COUNTRY_TO_META] {
    const meta = COUNTRY_TO_META[country as keyof typeof COUNTRY_TO_META];

    if (!meta)
        throw new Error(`Country ${country} not found in COUNTRY_TO_META`);

    return meta;
}
