/* will return void if unidentified sms format */
export default function intlPhoneNumber(phoneNr: string): string | void {
    let intlNumber;
    const trimmedPhoneNr = phoneNr.replace(/[^\d+]|(?!^)\+/g, ""); // trim all everything that is non digit, just keep the + at the beggining.
    if (trimmedPhoneNr.startsWith("86")) {
        intlNumber = trimmedPhoneNr.replace("86", "+3706");
    } else if (trimmedPhoneNr.startsWith("0")) {
        intlNumber = trimmedPhoneNr.replace("0", "+370");
    } else if (trimmedPhoneNr.startsWith("+370")) {
        intlNumber = trimmedPhoneNr;
    } else if (trimmedPhoneNr.startsWith("+1")) {
        intlNumber = trimmedPhoneNr;
    } else if (trimmedPhoneNr.length === 10 && /^\d/.test(trimmedPhoneNr)) {
        // is a local CA phone number that starts with digit and not a plus
        intlNumber = `+1${trimmedPhoneNr}`;
    } else {
        console.error(`Phone number unknown format: ${phoneNr}`);
        return;
    }

    return intlNumber;
}
