import Country from "@Shared/types/enums/Country";
import pickABTest from "./pickABTest";

export default function ABTestFunctional<T>(p: {
    newComponentPercentage?: number;
    experimentKey: string;
    new: T;
    default: T;
}): T {
    if (
        p.experimentKey === "SEARCH_MAP_ZOOM_LEVEL" &&
        window._COUNTRY === Country.CA
    ) {
        return p.default;
    }
    const keyToSet = pickABTest({
        experimentKey: p.experimentKey,
        newComponentPercentage: p.newComponentPercentage,
    });

    return keyToSet === p.experimentKey ? p.new : p.default;
}
