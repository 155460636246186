import React from "react";
import NavBar from "./NavBar/NavBar";
import * as S from "./Header.styled";
import {
    ClientPage,
    countryClientPageToMeta,
} from "@Shared/util/clientPagesMeta";
import InternalLink from "@FEShared/components/InternalLink/InternalLink";
import Logo from "@FEShared/components/UI/Logo/Logo";
import { observer } from "mobx-react-lite";
import useStore from "@FEClient/logic/store";
import useIsMobile from "@FEShared/hooks/useIsMobile";
import { Cross as Hamburger } from "hamburger-react";
import { runInAction } from "mobx";
import Box from "@FEShared/components/UI/Box/Box";
import SearchHeader from "./SearchHeader/SearchHeader";
import { useTheme } from "styled-components";
import KUROHUDAS_LOGO from "./assets/kurohudas.svg";
import Text from "@FEShared/components/UI/Text/Text";
import Icon from "@FEShared/components/UI/Icon/Icon";

const Header: React.FC<{}> = observer((_p) => {
    const GS = useStore();
    const isMobile = useIsMobile();
    const showNavBar = !GS.activePageMeta?.hideNavBar && !GS.refFrom;
    const theme = useTheme();

    if (isMobile && GS.activePage === ClientPage.WORKSHOP) {
        // this is a temp solution. For now we are hiding header/footer inside `App.tsx` but should be migrated to clientsPageMeta
        return null;
    }

    if (GS.activePage === ClientPage.SEARCH) {
        return <SearchHeader />;
    }

    return (
        <S.HeaderContainer
            className="notranslate"
            $page={GS.activePage}
            $notSticky={GS.activePageMeta?.notSticky}
        >
            <S.HeaderContent $page={GS.activePage}>
                <InternalLink
                    to={
                        GS.activePageMeta?.unclickableLogo
                            ? "#"
                            : countryClientPageToMeta(window._COUNTRY).HOME.url
                    }
                    sx={{
                        pointerEvents: GS.activePageMeta?.unclickableLogo
                            ? "none"
                            : "auto",
                    }}
                    aria-label="nevaziuoja-logo"
                >
                    {GS.refFrom ? (
                        <>
                            <img
                                src={KUROHUDAS_LOGO}
                                alt="Kurohudas logo"
                                style={{ width: "100px" }}
                            />
                            <Text
                                fontSize={9}
                                color="black"
                                display="flex"
                                alignItems="center"
                                mt={"-3px"}
                            >
                                Powered by{" "}
                                <Icon
                                    ml={"4px"}
                                    className="icon-logo-symbol"
                                    fontSize={9}
                                />{" "}
                                <Icon
                                    ml="4px"
                                    color="black"
                                    className="icon-text-logo"
                                    fontSize={9}
                                />
                            </Text>
                        </>
                    ) : (
                        <Logo />
                    )}
                </InternalLink>
                {showNavBar && !isMobile && <NavBar />}
                {!isMobile && (
                    <div
                        id="lang-switcher"
                        style={{ display: "inline-block" }}
                    ></div>
                )}
                {showNavBar && (
                    <Box
                        displayFlex
                        alignVertical="center"
                        sx={{
                            ml: 2,
                            [theme.breakpoints.down("md")]: {
                                ml: "auto",
                            },
                        }}
                    >
                        <div
                            id="lang-switcher"
                            style={{ display: "inline-block" }}
                        ></div>
                        <Hamburger
                            toggle={() =>
                                runInAction(() => {
                                    GS.showSidebar = true;
                                })
                            }
                            toggled={GS.showSidebar}
                            direction="right"
                        />
                    </Box>
                )}
            </S.HeaderContent>
        </S.HeaderContainer>
    );
});

export default Header;
