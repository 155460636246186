import { Calendar, CalendarDay } from "@FEShared/graphql/generated/graphql";
import { Weekday } from "@Shared/types/calendar";
import BusinessDateHandler, {
    NotAvailableReason,
} from "@Shared/util/BusinessDateHandler";
import { DateTime } from "luxon";
import { TimeSlot } from "./DatePicker.types";
import { dateObjToDate } from "@Shared/util/dateFormat";

const LAST_BOOK_HOUR = 21;

function generateTimes() {
    const timeSlots: string[] = [];
    for (let i = 7; i < LAST_BOOK_HOUR; i++) {
        for (let j = 0; j < 4; j++) {
            timeSlots.push(
                `${i.toString().padStart(2, "0")}:${(j * 15)
                    .toString()
                    .padStart(2, "0")}`
            );
        }
    }
    return timeSlots;
}
export const TIME_SLOTS = generateTimes();

export function getCalendarDay(
    date: Date,
    calendar: Calendar
): CalendarDay | undefined {
    const weekday = date
        .toLocaleDateString("en-EN", { weekday: "long" })
        .toLowerCase() as Weekday;
    const calendarDay = calendar[weekday];
    return calendarDay as CalendarDay | undefined;
}

export function filterTimes(
    p:
        | {
              YYYY_MM_DD: string;
              earliestAvailableDate: Date;
              /* find atleast one free timelot and stop */
              findOne?: boolean;
          }
        | {
              YYYY_MM_DD: string;
              bizDateHandler: BusinessDateHandler;
              earliestAvailableDate?: Date;
              clientWillWait?: boolean;
              findOne?: boolean;
          }
): TimeSlot[] {
    const filteredTimeslots: TimeSlot[] = [];

    // a quick heuristic to check if this day should have any timepicker slots at all and avoid uneccessary calculations.
    if (
        "bizDateHandler" in p &&
        !p.bizDateHandler.isDTBusinessDay(
            DateTime.fromJSDate(
                dateObjToDate({
                    YYYY_MM_DD: p.YYYY_MM_DD,
                    HH_MM: "12:00", // The time doesnot matter at all, since are just checking if this is a business day.
                })
            )
        )
    ) {
        return [];
    }

    for (const t of TIME_SLOTS) {
        const thisTimeDate = new Date(`${p.YYYY_MM_DD}T${t}`);
        if ("bizDateHandler" in p) {
            const isAfterEarliestAvlDate = p.earliestAvailableDate
                ? thisTimeDate.getTime() >= p.earliestAvailableDate.getTime()
                : true;

            if (!isAfterEarliestAvlDate) {
                continue;
            }

            const isAvailableRes = p.bizDateHandler.isAvailableForServices(
                DateTime.fromJSDate(thisTimeDate),
                {
                    immediateReservation: p.clientWillWait,
                }
            );

            if (isAvailableRes.isAvailable) {
                filteredTimeslots.push({
                    time: t,
                    enabled: true,
                });
            } else if (
                [
                    NotAvailableReason.LUNCH,
                    NotAvailableReason.BLOCKED_TIMESLOT,
                ].includes(isAvailableRes.reason)
            ) {
                filteredTimeslots.push({
                    time: t,
                    enabled: false,
                });
            }
            // if its disabled and not one of the reason we should show for, just filter it out completely.
        } else if (
            thisTimeDate.getTime() >= p.earliestAvailableDate.getTime()
        ) {
            filteredTimeslots.push({
                time: t,
                enabled: true,
            });
        }
        if (
            p.findOne &&
            filteredTimeslots.length > 0 &&
            filteredTimeslots[0]?.enabled
        ) {
            break;
        }
    }
    return filteredTimeslots;
}
